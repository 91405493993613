<i18n src="@/locales/dental_decontamination.json" />
<i18n src="@/locales/articles-card-content.json" />

<template>
  <div class="orthodontics">
    <div class="header-background">
      <ImanHeader
        :title="$t('h1_title')"
      />
    </div>
    <b-message>
      <p>
        <span v-html="$t('message_1')" />
      </p>
    </b-message>
    <ImanH2>{{ $t('h2_title_1') }}</ImanH2>
    <section :class="mainBackgroundClass + ` section`">
      <ImanHomeSection :img="img[0]">
        <p>
          {{ $t('article_p1') }}
        </p>
        <ImanH3>
          {{ $t('h3_title_1') }}
        </ImanH3>
        <p>
          {{ $t('article_p2') }}
        </p>
        <ImanH3>
          {{ $t('h3_title_2') }}
        </ImanH3>
        <p>
          {{ $t('article_p3') }}
        </p>
      </ImanHomeSection>
    </section>
    <section class="section">
      <ImanArticle>
        <h3 class="has-text-primary is-size-4">
          {{ $t('h3_title_3') }}
        </h3>
        <p>
          {{ $t('article_p4') }}
        </p>
      </ImanArticle>
    </section>
    <ImanH2>{{ $t('h2_title_2') }}</ImanH2>
    <section :class="mainBackgroundClass + ` section`">
      <ImanHomeSection :img="img[1]">
        <p>
          {{ $t('article_p5') }}
        </p>
      </ImanHomeSection>
      <ImanHomeSection :img="img[2]">
        <ImanH3>
          {{ $t('h3_title_4') }}
        </ImanH3>
        <p>
          {{ $t('article_p6') }}
        </p>
        <p>
          {{ $t('article_p7') }}
        </p>
        <p>
          {{ $t('article_p8') }}
        </p>
      </ImanHomeSection>
    </section>
    <ImanH2>{{ $t('h2_articles') }}</ImanH2>
    <ImanArticleSection :content-list="filteredArticlesCardContent" />
  </div>
</template>

<script>
  import ImanArticleSection from "@/components/ImanArticleSection";
  import {articlesCardContentFilterMixin} from "@/mixins/articles-card-content-filter-mixin";
  import {metaMixin} from "@/mixins/meta-mixin";

  export default {
    name: 'DentalDecontamination',
    components: {
      ImanArticleSection
    },
    mixins: [
      articlesCardContentFilterMixin,
      metaMixin
    ],
    data() {
      return {
        articlesCardContentId: [87, 65, 89, 811],
        mainBackgroundClass: 'none-background',
        img: [
          {
            path: 'dental-care/decotamination/toxicite-metaux-lourds-dentaires.jpg',
            position: 'right'
          },
          {
            path: 'dental-care/decotamination/decontamination-metaux-lourd-remplacement-amalgame.jpg',
            position: 'right'
          },
          {
            path: 'dental-care/decotamination/amalgame-dentaire.jpg',
            position: 'right'
          }
        ]
      }
    },
    computed: {
      openGraphImgPath () {
        return 'https://anveli.dental' + require('../../../assets/img/thumbnail/MINIATURE_3.7.png')
      }
    },
    created() {
      window.addEventListener('scroll', this.whenScroll)
    },
    destroyed() {
      window.removeEventListener('scroll', this.whenScroll)
    },
    methods: {
      whenScroll() {
        if (document.body.scrollTop > 1 || document.documentElement.scrollTop > 1) {
          this.mainBackgroundClass = 'none-background'
        }
        if (document.body.scrollTop > 1000 || document.documentElement.scrollTop > 1000) {
          this.mainBackgroundClass = 'fixed-background'
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .header-background {
    @media only screen and (min-width: 768px) {
      /* tablet et ordinateurs */
      background-image: url("../../../assets/img/dental-care/decotamination/H1-amalgame-aux-mercure-toxique-d.jpg");
    }
    @media only screen and (max-width: 767px) {
      /* smartphone en mode paysage */
      background-image: url("../../../assets/img/dental-care/decotamination/H1-amalgame-aux-mercure-toxique-t.jpg");
    }
    @media only screen and (max-width: 767px) and (orientation: portrait) {
       /* smartphone en mode portait */
      background-image: url("../../../assets/img/dental-care/decotamination/H1-amalgame-aux-mercure-toxique-m.jpg");
    }
  }
</style>
